import React from 'react';
import {icons} from '../../assets';
import {Link} from 'react-router-dom';

export const PaymentSuccess = () => {

  return (
    <div className="Form flex flex-1 h-screen flex-col items-center justify-center bg-neutral-50">
      <div className="w-1/3 h-1/3 mt-10">
        <div className="LogoDark flex justify-center items-center bg-black rounded-full mb-6 mx-auto">
          <img
            className="IconWhiteWithColor w-16 h-16"
            src={icons.logo}
            alt="App logo"
            loading="lazy"
          />
        </div>
        <h1 className="StartYourCryptoIn text-center text-neutral-900 text-2xl font-bold">
          Payment is Successful
        </h1>
        <Link
          to={`/`}
          className="bg-primary rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
        >
          Return to dashboard
        </Link>
      </div>
    </div>
  );
};
