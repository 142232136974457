import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPropBets, getLiveScores, getPropBetsPlayers, getPropBetsHomeTeamPlayers, getPropBetsAwayTeamPlayers } from '../redux/actions/SportsActions';
import _ from 'lodash';

export const usePropBets = (gameId, teamSelection, currentPage, userInfo, game) => {
  // const [propBets, setPropBets] = useState(null);
  const [playersData, setPlayersData] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const propBets = useSelector(state => state.getPropBets.propBets);
  const liveGame = useSelector(state=>state.getLiveGames?.liveGame);
  useEffect(() => {
    const fetchPropBets = async () => {
      setLoading(true);
        const propBetsData = await dispatch(getPropBets(gameId, game));
      
      dispatch(getPropBetsHomeTeamPlayers(propBetsData));
      dispatch(getPropBetsAwayTeamPlayers(propBetsData));

      // setPropBets(propBetsData);
      setPlayersData([]);
      setLoading(false);
    };

    if (gameId) {
      fetchPropBets();
    }
  }, [gameId, dispatch]);

  useEffect(() => {
    if (propBets && Object.keys(propBets).length) {
      dispatch(
        getLiveScores(
          propBets.gameScore?.sport.id,
          propBets.gameScore?.league.name,
          propBets.gameScore?.id,
        ),
      );
    }
  }, [propBets?.gameScore, dispatch]);

  const fetchPlayerDataForTeam = async (teamPlayers, teamId, sport) => {
    const playerIds = teamPlayers.map(player => player.id);
    return await dispatch(getPropBetsPlayers(teamId, playerIds,sport));
  };

  const fetchPlayersData = async (page) => {
    if (propBets?.gameScore?.status) {
      setLoading(true);
      if (propBets?.gameScore?.status === 'completed') {
        const homePlayersData = await fetchPlayerDataForTeam(propBets?.home_team_players, propBets?.home_team_players[0].team?.id,propBets.gameScore?.sport.id);
        const awayPlayersData = await fetchPlayerDataForTeam(propBets?.away_team_players, propBets?.away_team_players[0].team?.id,propBets.gameScore?.sport.id);
        setPlayersData({
          homePlayers: homePlayersData,
          awayPlayers: awayPlayersData,
        });
        return;
      } else if (['unplayed', 'live'].includes(propBets.gameScore.status)) {
        const playersPerPage = 20;
        const teamPlayers = teamSelection ? propBets?.home_team_players : propBets?.away_team_players;
        const startIndex = (page - 1) * playersPerPage;
        const endIndex = startIndex + playersPerPage;
        const oddsByPlayerId = _.groupBy(propBets?.gameOdds?.odds, 'player_id');
        let activePlayers = teamPlayers.filter(player => player.is_active);
        let playersWithOdds = [];

        for (let player of teamPlayers) {
          if (oddsByPlayerId[player.id]) {
            playersWithOdds.push({
              ...player,
              odds: oddsByPlayerId[player.id],
              oddsCount: oddsByPlayerId[player.id].length,
            });
          }
        }

        playersWithOdds.sort((a, b) => b.oddsCount - a.oddsCount);

        if (playersWithOdds.length > 0) {
          activePlayers = playersWithOdds.concat(
            activePlayers.filter(player => !oddsByPlayerId[player.id]),
          );
        }
        const playerIds = activePlayers
          .slice(startIndex, endIndex)
          .map(player => player.id);

        const newPlayersData = await dispatch(
          getPropBetsPlayers(activePlayers[0].team?.id, playerIds,propBets.gameScore?.sport.id),
        ); 
        setPlayersData(newPlayersData.playersWithResults.players);
        setIsSubscribed(newPlayersData.playersWithResults.isSubscribed);

        
        if (!userInfo && !userInfo?.id) {
          setShowTooltip(true);
          setTimeout(() => setShowTooltip(false), 6000);
          // return;
        } else {
          setModalVisible(!newPlayersData.playersWithResults.isSubscribed);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!loading && propBets?.away_team_players && propBets?.home_team_players) {
      fetchPlayersData(1);
    }
  }, [propBets, teamSelection, currentPage]);

  useEffect(() => {
    const fetchLiveScores = async () => {
      try {
        await dispatch(
          getLiveScores(
            propBets.gameScore?.sport,
            propBets.gameScore?.league,
            propBets.gameScore?.id,
          ),
        );
      } catch (error) {
        console.error('Error fetching live scores:', error);
      }
    };

    let intervalId;
    if (propBets && propBets.gameScore && propBets.gameScore.is_live) {
      fetchLiveScores();
      intervalId = setInterval(fetchLiveScores, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [propBets?.gameScore, dispatch]);

  const handleUserAccessForData = () => {
    if (!userInfo?.id) {
      setShowTooltip(true);
      setTimeout(() => setShowTooltip(false), 6000);
    } else {
      setModalVisible(true);
    }
  };

  return {
    propBets,
    liveGame,
    playersData,
    isSubscribed,
    modalVisible,
    showTooltip,
    loading,
    fetchPlayersData,
    handleUserAccessForData,
    setModalVisible,
    setShowTooltip,
  };
};
