import { Link } from 'react-router-dom';
import { PlayerInfoCharts } from './PlayerInfoCharts';
import { useEffect, useRef, useState } from 'react';
import { formatPlayerStats } from '../../../utils/sportStats/playerStats';
import { Loading } from '../../../components/LoadingComponent';

export const PlayerStats = ({ player, handleUserAccessForData, selectedStatValue,darkMode }) => {
  const [formattedStats, setFormattedStats] = useState([]);
  const isSubscribed = player.isSubscribed
  useEffect(() => {
    if (player.playerDetails[0]) {
      const statArray = formatPlayerStats(player.playerDetails[0]?.sport.id)


      // const filteredArray = statArray.filter(item =>
      //   player.playerResultsWithScores?.some(obj => (item.gameStat in obj.stats[0].stats) && obj.stats[0].stats[item.gameStat] != 0)
      // );
      setFormattedStats(statArray);
    }
  }, [player]);

  const generateStatMap = stats => {
    let mapArray = [];
    stats.forEach(stat => {
      mapArray.push({
        key: stat?.stat,
        value: stat?.value,
        display: stat?.stat,
        gameStat: stat?.gameStat,
      });
    });
    return mapArray;
  };
  const statMap = generateStatMap(formattedStats);
const selectedStatMap = statMap.filter(stat => stat.gameStat == selectedStatValue);
const statValue=  selectedStatMap[0]? selectedStatMap[0].value : statMap[0]?.value;
const statDisplay=  selectedStatMap[0]? selectedStatMap[0].display : statMap[0]?.display;
  const [selectedStat, setSelectedStat] = useState(statValue);

  const [selectedButton, setSelectedButton] = useState(statDisplay);
  const [selectedGamesButton, setSelectedGamesButton] = useState('All');

  const handleGamesButtonClick = (button, isClickable) => {
    if (isSubscribed || isClickable) {
      setSelectedGamesButton(button);
    } else {
      handleUserAccessForData();
    }
  };

  const initialSetRef = useRef(false);

  if (!initialSetRef.current && statMap.length > 0) {
    setSelectedStat(statValue);
    setSelectedButton(statDisplay);
    initialSetRef.current = true; // Mark as set
  }

  const handleButtonClick = (statKey, isClickable) => {
    if (isSubscribed || isClickable) {
      const statObj = statMap.find(stat => stat.value === statKey);
      setSelectedStat(statObj?.value);
      setSelectedButton(statObj?.display);
    } else {
      handleUserAccessForData(); // Open the modal when the user is not subscribed and clicks on a non-clickable stat
    }
  };
  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-col gap-3 w-full justify-between">
        {/* Stats Buttons Container */}
        <div className={`PlayerStats h-2/5 flex flex-row items-center  overflow-x-auto whitespace-nowrap ${darkMode ? 'text-white' : 'text-black'}`}>
          {formattedStats.map((stat, index) => {
            // Only allow the first stat to be clickable if not subscribed
            const isClickable = isSubscribed || index === 0;
            return (
              <button
                key={index}
                onClick={() => handleButtonClick(stat.value, isClickable)}
                className={`h-9 p-3 mr-2 rounded-full flex items-center justify-center text-sm xs:text-base ${selectedButton === stat.stat ? 'bg-primary text-black font-bold' : 'text-slate-500'}`}
              >
                {stat.stat}
              </button>
            )
          })}
        </div>

        {/*<div className="h-1/3 flex flex-row items-center justify-between overflow-x-auto whitespace-nowrap">
          <button className="h-9 p-1.5 bg-primary rounded-full flex items-center justify-center flex-grow">
            <div className="text-black text-sm xs:text-base font-bold leading-normal mx-3.5">All</div>
          </button>

          {
            player.playerResultsWithScores.slice(0, 7).map((game, index) => (
              <Link to={`/sports/propBets/${game.game_id}`} className="p-1.5 rounded-full flex flex-col items-center justify-center flex-grow" key={index} >
                <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">
                  {game?.gameScore?.home_team_abb} v {game?.gameScore?.away_team_abb}
                </span>
                <span className="text-slate-500 text-xs font-medium leading-none">
                  {formatDateStringToShort(game?.gameScore?.start_date)}
                </span>
              </Link>
            ))
          }
        </div>*/}

        <div className="h-1/3 w-full flex flex-row items-center justify-between">
          <button className="h-9 p-1.5 bg-primary rounded-full flex items-center justify-center flex-grow">
            <span className="text-black text-sm xs:text-base font-bold leading-normal mx-3">
              Under
            </span>
          </button>

          <div className="p-1.5 rounded-full flex items-center justify-center flex-grow">
            <span className="text-slate-500 text-sm xs:text-base font-normal leading-normal">
              |
            </span>
          </div>

          <button
            className={`flex-grow justify-center items-center h-9 p-1.5 gap-3.5 text-sm xs:text-base flex ${selectedGamesButton === 'All' ? 'bg-primary rounded-full text-black font-bold' : 'text-slate-500'}`}
            onClick={() => handleGamesButtonClick('All', false)}
          >
            All
          </button>

          <button
            className={`flex-grow justify-center items-center h-9 p-1.5 gap-3.5 text-sm xs:text-base flex ${selectedGamesButton === 'L10' ? 'bg-primary rounded-full text-black font-bold' : 'text-slate-500'}`}
            onClick={() => handleGamesButtonClick('L10', true)}
          >
            Last 10
          </button>

          <button
            className={`flex-grow justify-center items-center h-9 p-1.5 gap-3.5 text-sm xs:text-base flex ${selectedGamesButton === 'L5' ? 'bg-primary rounded-full text-black font-bold' : 'text-slate-500'}`}
            onClick={() => handleGamesButtonClick('L5', true)}
          >
            Last 5
          </button>

          <button
            className={`flex-grow justify-center items-center h-9 p-1.5 gap-3.5 text-sm xs:text-base flex ${selectedGamesButton === 'vs' ? 'bg-primary rounded-full text-black font-bold' : 'text-slate-500'}`}
            onClick={() => handleGamesButtonClick('vs', false)}
          >
            vs. Opp
          </button>

          <button
            className={`flex-grow justify-center items-center h-9 p-1.5 gap-3.5 text-sm xs:text-base flex ${selectedGamesButton === 'L20' ? 'bg-primary rounded-full text-black font-bold' : 'text-slate-500'}`}
            onClick={() => handleGamesButtonClick('L20', false)}
          >
            Last 20
          </button>
        </div>
      </div>
      {player && statMap && selectedStat ?(
      <PlayerInfoCharts
        player={player}
        statMap={statMap}
        selectedStat={selectedStat}
        selectedButton={selectedGamesButton}
      />):(
       <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <Loading />
      </div>)
}
    </div>
  );
};
