// eslint-disable-next-line import/no-anonymous-default-export
export default {
  logo: require('./logo.png'),
  logoDark: require('./logo-dark.png'),
  bigLogoLight: require('./logoLight.png'),
  bigLogoDark: require('./logoDark.png'),
  googleLogo: require('./google_logo.png'),
  facebookLogo: require('./facebook_logo.png'),
  facebookLogoWhite: require('./facebook-icon-white.png'),
  tiktokLogoWhite: require('./tiktok-white.png'),
  linkedinLogoWhite: require('./linkedin-white.webp'),
  instagramLogoWhite: require('./instagram-logo-white.png'),
  twitterLogoWhite: require('./twitter-logo-white.png'),
  ytLogoWhite: require('./youtube-white.webp'),
  appleLogo: require('./apple_logo.png'),
  sidebarLogo: require('./sidebar_logo.png'),
  blacklogo: require('./Blacklogo.png'),
  bell: require('./bell.png'),
  bitcoin: require('./Bitcoin.png'),
  bitcoinLight: require('./BitcoinLight.png'),
  chart: require('./Chart.png'),
  chartLight: require('./ChartLight.png'),
  coin: require('./Coin.png'),
  coinLight: require('./CoinLight.png'),
  cup: require('./cup.png'),
  cupLight: require('./cupLight.png'),
  dashboard: require('./Dashboard.png'),
  dashboardLight: require('./DashboardLight.png'),
  headphones: require('./headphones.png'),
  award: require('./Icon_award.png'),
  chevronDown: require('./Icon_chevron-down.png'),
  chevronDownLight: require('./Icon_chevron-down-light.png'),
  chevronRight: require('./chevron-right.png'),
  trade: require('./trade_icon.png'),
  tradeColor: require('./trade-color.png'),
  tradeLight: require('./trade-icon-light.png'),
  search: require('./search-icon.png'),
  save: require('./Icon_save.png'),
  notification: require('./notification.png'),
  notificationOn: require('./notificationOn.png'),
  star: require('./star.png'),
  starHalf: require('./star-half.png'),
  starDark: require('./star-dark.png'),
  play: require('./play_arrow.png'),
  calendar: require('./calendar.png'),
  emoji: require('./emoji.png'),
  gallery: require('./gallery.png'),
  gif: require('./gif.png'),
  plus: require('./plus.png'),
  plusCircle: require('./plusCircle.png'),
  stats: require('./stats.png'),
  xIcon: require('./xIcon.png'),
  climateChange: require('./climate-change.png'),
  bxCross: require('./bx_cross.png'),
  hideIcon: require('./hide.png'),
  mesh: require('./mesh.png'),
  padlock: require('./padlock.png'),
  polyline: require('./polyline.png'),
  ruler: require('./ruler.png'),
  shockerEmoji: require('./shocker-emoji.png'),
  text: require('./text.png'),
  trashCan: require('./trash-can.png'),
  unit: require('./unit.png'),
  zoom: require('./zoom-mode.png'),
  formula: require('./formula-fx.png'),
  alarmClock: require('./alarm-clock.png'),
  add: require('./add.png'),
  replyLeft: require('./reply-arrow-left.png'),
  replyRight: require('./reply-arrow-right.png'),
  camera: require('./camera.png'),
  tickBlue: require('./tick-blue.png'),
  graphGreen: require('./graphGreen.png'),
  graphRed: require('./graphRed.png'),
  walletIcon: require('./walletIcon.png'),
  calling: require('./Calling.png'),
  heart: require('./Heart.png'),
  video: require('./Video.png'),
  arrowDownWhite: require('./arrowDownWhite.png'),
  searchWhite: require('./Search.png'),
  emojiColor: require('./emojiColor.png'),
  plusSquare: require('./plusSquare.png'),
  voice: require('./Voice.png'),
  pause: require('./pause.png'),
  comment: require('./comment.png'),
  retweet: require('./retweet.png'),
  like: require('./like.png'),
  likeFill: require('./likeFill.png'),
  share: require('./share.png'),
  arrowRight: require('./arrow-right.png'),
  activeContributor: require('./activeContributor.png'),
  championshipChatter: require('./championshipChatter.png'),
  sportsEnthusiast: require('./sportsEnthusiast.png'),
  marketMentor: require('./marketMentor.png'),
  newbieNavigator: require('./newbieNavigator.png'),
  checkMark: require('./checkMark.png'),
  checkMarkWhite: require('./checkMarkWhite.png'),
  newspapers: require('./newspapers.png'),
  articles: require('./articles.png'),
  info: require('./info.png'),
  language: require('./language.png'),
  logout: require('./logout.png'),
  profile: require('./profile.png'),
  security: require('./security.png'),
  settings: require('./settings.png'),
  colorTheme: require('./colorTheme.png'),
  delete: require('./delete.png'),
  yellowStar: require('./yellowStar.png'),
  game: require('./game.png'),
  team: require('./team.png'),
  verifiedUser: require('./verified-check-icon.png'),
  sportsbook: require('./sportsbook.png'),
  sportsbookLight: require('./sportsbookLight.png'),
  education: require('./education.png'),
  educationLight: require('./educationLight.png'),
  menu: require('./menu.png'),
  menuLight: require('./menuLight.png'),
  close: require('./close.png'),
  closeLight: require('./closeLight.png'),
  moon: require('./moon.png'),
  sun: require('./sun.png'),
  shopify: require('./shopify.png'),
  vs: require('./vs.png'),
};
