import {Link} from 'react-router-dom';
import {formatDateRelativeToToday} from '../../../utils/dateUtils';
import {transformBetString} from '../../../utils/sportStats/oddsFunctions';

export const PastResults = ({stats, team, bgColor}) => {
  return (
    <div className="w-full bg-white">
      <table className="w-full border-collapse border-2 border-gray-400">
        <thead className="bg-slate-200" style={{backgroundColor: bgColor}}>
          <tr>
            <th
              colSpan="4"
              className="py-2 px-4 font-bold text-lg border-2 border-gray-400 text-start"
            >
              Last 10 • {stats[0]?.season_type}
            </th>
          </tr>
          <tr>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              Date
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              Score (H-A)
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              ATS
            </th>
            <th className="font-bold px-1 py-2 border-2 border-gray-400">
              O/U
            </th>
          </tr>
        </thead>
        <tbody>
          {stats &&
            stats.length > 0 &&
            stats.map((game, index) => (
              <tr key={index} className="border-2 border-gray-400">
                <td className="p-1 sm:p-3 border-2 w-1/5 sm:w-1/4 border-gray-400 text-black text-sm sm:text-lg font-normal leading-relaxed tracking-tight">
                  {formatDateRelativeToToday(game[0].fixture.start_date)}
                </td>
                <td className="p-1 sm:p-3 border-2 w-1/2 sm:w-1/4 border-gray-400">
                  <div className="flex items-center justify-around">
                    <img
                      className="w-6 lg:w-8 h-6 lg:h-8"
                      src={
                        game[0].fixture.home_competitors && game[0].fixture.home_competitors[0].logo
                        ? game[0].fixture.home_competitors[0].logo
                          : 'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                      }
                      alt="Home Team"
                      loading="lazy"
                    />
                    <span className="font-bold underline text-xs lg:text-base">{`${game[0].fixture.home_competitors[0].abbreviation} ${game[0].scores.home.total}`}</span>
                    <img
                      className="w-6 lg:w-8 h-6 lg:h-8"
                      src={
                        game[0].fixture.away_competitors && game[0].fixture.away_competitors[0].logo
                          ? game[0].fixture.away_competitors[0].logo
                          : 'https://www.adaptivewfs.com/wp-content/uploads/2020/07/logo-placeholder-image.png'
                      }
                      alt="Versus"
                      loading="lazy"
                    />
                    <span className="font-bold underline text-xs lg:text-base">{`${game[0].fixture.away_competitors[0].abbreviation} ${game[0].scores.away.total}`}</span>
                  </div>
                </td>
                <td className="p-3 border-2 w-1/6 sm:w-1/4 border-gray-400 font-bold">
                  {game.ats}
                </td>
                <td className="p-3 border-2 w-1/6 sm:w-1/4 border-gray-400">
                  {transformBetString(game.odds, team)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
