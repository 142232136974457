export const sportsData = [
  {
    league: 'NFL',
    sport: 'football',
    title: 'NFL',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png',
  },
  {
    league: 'NBA',
    sport: 'basketball',
    title: 'NBA',
    imageUrl: 'https://pngimg.com/d/nba_PNG15.png',
  },
  // {
  //   league: 'PGA',
  //   sport: 'golf',
  //   title: 'PGA',
  //   imageUrl: 'https://logowik.com/content/uploads/images/pga-tour5032.logowik.com.webp'
  // },
  {
    league: 'MLB',
    sport: 'baseball',
    title: 'MLB',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Major_League_Baseball_logo.svg/1200px-Major_League_Baseball_logo.svg.png',
  },
  // {
  //   league: 'NHL',
  //   sport: 'hockey',
  //   title: 'NHL',
  //   imageUrl: 'https://logos-world.net/wp-content/uploads/2021/09/NHL-Logo.png',
  // },
  {
    teamId: '73AEB6743EC2',
    league: 'NBA',
    sport: 'basketball',
    title: 'Leakers',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Los_Angeles_Lakers_logo.svg/1200px-Los_Angeles_Lakers_logo.svg.png',
  },
  {
    league: 'NCAAF',
    sport: 'football',
    title: 'NCAAF',
    imageUrl:
      'https://seeklogo.com/images/N/ncaa-football-logo-36487E0FA2-seeklogo.com.png',
  },
  {
    teamId: '26BB4DC5722F',
    league: 'NBA',
    sport: 'basketball',
    title: 'Miami Heat',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/en/thumb/f/fb/Miami_Heat_logo.svg/800px-Miami_Heat_logo.svg.png',
  },
  {
    sport: 'basketball',
    title: 'Basketball',
    imageUrl:
      'https://img.freepik.com/free-vector/basketball-logo-orange-ball_78370-1101.jpg',
  },
  {
    teamId: '032D4F9C6C55',
    league: 'NFL',
    sport: 'football',
    title: 'New York Jets',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/nfl/new_york_jets.png',
  },
  {
    sport: 'baseball',
    title: 'Baseball',
    imageUrl:
      'https://seeklogo.com/images/B/baseball-logo-8DD18EC9B4-seeklogo.com.png',
  },
  {
    league: 'UFC',
    sport: 'mma',
    title: 'UFC',
    imageUrl:
      'https://logowik.com/content/uploads/images/ufc-ultimate-fighting-championship3349.jpg',
  },
  {
    teamId: '0DBD3AEC9A84',
    league: 'NFL',
    sport: 'football',
    title: 'Denver Broncos',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/nfl/denver_broncos.png',
  },
  {
    teamId: '121EBAED4535',
    league: 'MLB',
    sport: 'baseball',
    title: 'New York Yankees',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/mlb/new_york_yankees.png',
  },
  {
    teamId: '23CD4450ABA7',
    league: 'NHL',
    sport: 'hockey',
    title: 'Ottawa Senators',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/nhl/ottawa_senators.png',
  },
  {
    teamId: '4F11A5896C24',
    league: 'MLB',
    sport: 'baseball',
    title: 'Houston Astros',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/mlb/houston_astros.png',
  },
  {
    teamId: '3C2AC63F5318',
    league: 'NHL',
    sport: 'hockey',
    title: 'New York Rangers',
    imageUrl: 'https://cdn.oddsjam.com/team-logos/nhl/new_york_rangers.png',
  },
  {
    teamId: '01FBDB1511E0',
    league: 'NCAAF',
    sport: 'football',
    title: 'Memphis',
    imageUrl: 'https://a.espncdn.com/i/teamlogos/ncaa/500/235.png',
  },
  {
    teamId: '048E01CFDFFD',
    league: 'NCAAF',
    sport: 'football',
    title: 'Abilene Christian',
    imageUrl: 'https://a.espncdn.com/i/teamlogos/ncaa/500/2000.png',
  },
];

export const Leagues = [
  {
    league: 'NFL',
    sport: 'football',
    title: 'NFL',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/en/thumb/a/a2/National_Football_League_logo.svg/1200px-National_Football_League_logo.svg.png',
  },
  {
    league: 'NBA',
    sport: 'basketball',
    title: 'NBA',
    imageUrl: 'https://pngimg.com/d/nba_PNG15.png',
  },
  // {
  //   league: 'UFC',
  //   sport: 'mma',
  //   title: 'UFC',
  //   imageUrl:
  //     'https://logowik.com/content/uploads/images/ufc-ultimate-fighting-championship3349.jpg',
  // }, 
  {
    league: 'MLB',
    sport: 'baseball',
    title: 'MLB',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Major_League_Baseball_logo.svg/1200px-Major_League_Baseball_logo.svg.png',
  },
  // {
  //   league: 'CS2',
  //   sport: 'esports',
  //   title: 'CS2',
  //   imageUrl: 'https://logowik.com/content/uploads/images/counter-strike-29548.logowik.com.webp',
  // },
  {
    league: 'WNBA',
    sport: 'basketball',
    title: 'WNBA',
    imageUrl: 'https://logowik.com/content/uploads/images/wnba6745.jpg',
  },
  {
    league: 'NCAAF',
    sport: 'football',
    title: 'NCAAF',
    imageUrl:
      'https://seeklogo.com/images/N/ncaa-football-logo-36487E0FA2-seeklogo.com.png',
  },
  // {
  //   league: 'League of Legends',
  //   sport: 'esports',
  //   title: 'League of Legends',
  //   imageUrl: 'https://logowik.com/content/uploads/images/league-of-legends7103.jpg',
  // },
  // {
  //   league: 'NCAAB',
  //   sport: 'basketball',
  //   title: 'NCAAB',
  //   imageUrl:
  //     'https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/NCAA_logo.svg/2048px-NCAA_logo.svg.png',
  // },
  //  {
  //   league: 'PGA',
  //   sport: 'golf',
  //   title: 'PGA',
  //   imageUrl: 'https://logowik.com/content/uploads/images/pga-tour5032.logowik.com.webp'
  // },
  {
    league: 'Valorant',
      sport: 'esports',
      title: 'Valorant',
      imageUrl: 'https://logowik.com/content/uploads/images/valorant4999.jpg',
  },
  {
    league: 'NHL',
    sport: 'hockey',
    title: 'NHL',
    imageUrl: 'https://logos-world.net/wp-content/uploads/2021/09/NHL-Logo.png',
  },

  // {
  //   league: 'USA - Major League Soccer',
  //   sport: 'soccer',
  //   title: 'MLS',
  //   imageUrl:'https://logowik.com/content/uploads/images/mls-crest-gradient2221.logowik.com.webp',
  // },
  // {
  //   league: 'Call of Duty',
  //   sport: 'esports',
  //   title: 'COD',
  //   imageUrl: 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.cleanpng.com%2Fpng-call-of-duty-black-ops-ii-call-of-duty-ghosts-call-5409738%2F&psig=AOvVaw1D3DEhCPmDAK9nOY2upXsi&ust=1719676271771000&source=images&cd=vfe&opi=89978449&ved=0CBEQjRxqFwoTCMDcx7zT_oYDFQAAAAAdAAAAABAE',
  //   }
];
