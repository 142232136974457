import {
  sportsStatToAbbrevMap,
  sportsStatToOddsMap,
  statMapping,
} from './statsMapping';

import moment from 'moment';

export const calculateStats = (lastGame, statsMap) => {
  return statsMap.map(statMap => {
    const statValue = lastGame?.stats[0].stats[statMap.stat] || 0; // Get the stat value, default to 0 if undefined
    return {
      ...statMap, // Spread the existing statMap object to include 'stat' and 'display'
      value: statValue, // Add a new key 'value' to hold the stat value
    };
  });
};

export const sortByProperty = (array, property, order = 'asc') => {
  return array.sort((a, b) => {
    // For dates or other types that might need parsing/conversion, add conditions here
    const valueA = new Date(a[property]);
    const valueB = new Date(b[property]);
   
    if (order === 'asc') {
      return valueA - valueB;
    } else {
      return valueB - valueA; // Default to descending if not specified otherwise
    }
  });
};

export const calculateStatPerGame = (results, stat) => {
  // Sum all values of the specified stat
  const total = results.reduce((acc, game) => acc + (game?.stats[0].stats[stat] || 0), 0);
  // Calculate the average by dividing the total by the number of games
  const average = results.length > 0 ? total / results.length : 0;
  return Number(average.toFixed(1));
};

export const getLastGameStats = (player, stats) => {
  if (
    !player?.playerResultsWithScores ||
    !Array.isArray(player.playerResultsWithScores)
  ) {
    return null;
  }

  // Filter games that are completed
  const completedGames = player.playerResultsWithScores.filter(
    game => game.status === 'completed',
  ).map((game => {
    game.start_date = game?.fixture?.start_date
  return game;
  }));

  // Use the extracted sorting function
  const sortedCompletedGames = sortByProperty(
    completedGames,
    'start_date',
    'desc',
  );

  // The first game in the sorted array is the most recent completed game
  const lastGame = sortedCompletedGames[0];
  return lastGame ? calculateStats(lastGame, stats) : null;
};


export const getVsGameStats = (player, stats, playerTeamName, oppTeam) => {
  if (
    !player?.playerResultsWithScores ||
    !Array.isArray(player.playerResultsWithScores)
  ) {
    return null;
  }

  // Filter games played against the specified team
  const filteredResults = player.playerResultsWithScores.filter(
		(result) => {
			const homeTeam = result.fixture?.home_competitors?.[0]?.name;
  		const awayTeam = result.fixture?.away_competitors?.[0]?.name;
			return (
				(homeTeam === playerTeamName && awayTeam === oppTeam) ||
    		(homeTeam === oppTeam && awayTeam === playerTeamName)
			);
		}
	);

  if (filteredResults.length === 0) {
    // No games played against the specified team
    return stats.map(statMap => ({...statMap, value: 0}));
  }

  // Calculate average stats across all games played against the specified team
  const avgStats = stats.map((statMap) => {
    const totalValue = filteredResults.reduce(
      (acc, game) => acc + (game.stats?.[0]?.stats?.[statMap.stat] || 0),
			0,
		);
    const avgValue = totalValue / filteredResults.length;
    return {
      ...statMap,
      value: Number(avgValue.toFixed(1)), // Format to one decimal place
    };
  });

  return avgStats;
};

export const getAwayStats = (player, statsMap) => {
  if (
    !player?.playerResultsWithScores ||
    !Array.isArray(player.playerResultsWithScores)
  ) {
    return null;
  }
  // Filter games that are completed and where the player's team was the away team
  const awayGames = player?.playerResultsWithScores.filter(
    game =>
      game.status === 'completed' &&
      game.gameScore?.away_team === player?.playerDetails[0]?.team_name,
  );

  // Use the extracted sorting function
  const sortedAwayGames = sortByProperty(awayGames, 'start_date', 'desc');

  // The first game in the sorted array is the most recent completed away game
  const lastAwayGame = sortedAwayGames[0];
  return lastAwayGame ? calculateStats(lastAwayGame, statsMap) : null;
};

export const filterGames = (player, button) => {
  //const currentYear = new Date().getFullYear();
  const currentYear = moment().subtract(1, "year").year();

  switch (button) {
    case [currentYear]:
      return player.playerResultsWithScores.filter(game => {
        const gameYear = new Date(game?.gameScore?.start_date).getFullYear();
        return gameYear === currentYear || gameYear === currentYear + 1;
      });
    case 'L10':
      return player.playerResultsWithScores.slice(0, 10);
    case 'L5':
      return player.playerResultsWithScores.slice(0, 5);
    case 'L20':
      return player.playerResultsWithScores.slice(0, 20);
    case 'All':
    case 'vs':
    default:
      return player.playerResultsWithScores;
  }
};

export const calculatePlayerStat = (player, button, stat) => {
  let filteredGames = filterGames(player, button);
  if (filteredGames.length === 0) return 0;

  const totalStat = filteredGames.reduce((acc, game) => {
    const statValue = typeof game[stat] === 'number' ? game[stat] : 0;
    return acc + statValue;
  }, 0);

  return (totalStat / filteredGames.length).toFixed(1);
};

export const filterResultsByOption = (
  playerResultsWithScores,
  option,
  playerTeamName,
  oppTeam,
) => {
  //const currentYear = moment().year();
  const currentYear = moment().subtract(1, "year").year();
  
  switch (option) {

    case currentYear:
      // return playerResultsWithScores;
      return playerResultsWithScores.filter(
        result => new Date(result?.fixture?.start_date).getFullYear()  == currentYear,
      );
    case 'L10':
      return playerResultsWithScores.slice(0, 10);
    case 'L5':
      return playerResultsWithScores.slice(0, 5);
    case 'L20':
      return playerResultsWithScores.slice(0, 20);
    case 'ALL':
      return playerResultsWithScores;
    case 'vs':
      return playerResultsWithScores.filter(
        result =>
          (result.team?.name === playerTeamName ||
          result.team?.name === oppTeam)
      );
    default:
      return [];
  }
};

export const calculateTimeOnIceStatPercentage = (
  filteredResults,
  specificStat,
  line,
) => {
  /*
    // Convert each time-on-ice to total seconds, calculate average, then format back to "minutes:seconds"
    const totalSeconds = filteredResults.reduce((acc, result) => {
      const parts = result[specificStat].split(':');
      const minutes = parseInt(parts[0], 10);
      const seconds = parseInt(parts[1], 10);
      return acc + (minutes * 60 + seconds);
    }, 0);
    const averageSeconds = totalSeconds / filteredResults.length;
    const averageMinutes = Math.floor(averageSeconds / 60);
    const averageRemainingSeconds = Math.floor(averageSeconds % 60);
    return `${averageMinutes}:${averageRemainingSeconds < 10 ? '0' : ''}${averageRemainingSeconds}`;
    */
  // Convert line from "minutes:seconds" to total seconds
  if (!line && typeof line !== 'string') {
    return '0%';
  }
  const [lineMinutes, lineSeconds] = line.split(':').map(Number);
  const lineTotalSeconds = lineMinutes * 60 + lineSeconds;

  // Convert each time-on-ice to total seconds
  const totalSecondsAboveLine = filteredResults.reduce((acc, result) => {
    const [minutes, seconds] = result[specificStat].split(':').map(Number);
    const totalSeconds = minutes * 60 + seconds;
    return acc + (totalSeconds > lineTotalSeconds ? 1 : 0);
  }, 0);

  // Calculate the percentage of games above the line
  const percentageAboveLine =
    (totalSecondsAboveLine / filteredResults.length) * 100;
  return `${percentageAboveLine.toFixed(2)}%`;
};

export const calculateCombinedStatsPercentage = (
  filteredResults,
  specificStat,
  line,
) => {
  // Calculate the sum of combo stats for each game
  const comboStatsSums = calculateComboStats(filteredResults, specificStat);

  // Count the number of games where the combo stat sum is above the line
  const aboveLineCount = comboStatsSums.reduce(
    (acc, sum) => acc + (sum > line ? 1 : 0),
    0,
  );
  const totalGames = comboStatsSums.length;
  const percentageAboveLine = (aboveLineCount / totalGames) * 100;

  // Return formatted percentage string
  return (
    (percentageAboveLine % 1 === 0
      ? percentageAboveLine.toString()
      : percentageAboveLine.toFixed(2)) + '%'
  );
};

export const calculateStatsPercentage = (
  filteredResults,
  specificStat,
  line,
) => {
  // Count the number of times the specific stat was above the line
  const aboveLineCount = filteredResults.reduce(
    (acc, result) => acc + (result?.stats[0]?.stats[specificStat] > line ? 1 : 0),
    0,
  );
  // Calculate the percentage of games above the line
  const totalGames = filteredResults.length;
  const percentageAboveLine = (aboveLineCount / totalGames) * 100;

  // Format the percentage string
  return (
    (percentageAboveLine % 1 === 0
      ? percentageAboveLine.toString()
      : percentageAboveLine.toFixed(2)) + '%'
  );
};

export const calculateStat = (
  playerResultsWithScores,
  specificStat,
  selectedButton,
  option,
  line,
  oppTeam,
  playerTeamName,
) => {
  const filteredResults = filterResultsByOption(
    playerResultsWithScores,
    option,
    playerTeamName,
    oppTeam,
  );
  // Check if there are no games in the filtered results
  if (filteredResults.length === 0) {
    return null;
  }

  if (specificStat === 'stat_time_on_ice') {
    return calculateTimeOnIceStatPercentage(
      filteredResults,
      specificStat,
      line,
    );
  } else if (
    selectedButton?.includes('+') ||
    specificStat?.includes('touchdowns')
  ) {
    return calculateCombinedStatsPercentage(
      filteredResults,
      specificStat,
      line,
    );
  } else {
    return calculateStatsPercentage(filteredResults, specificStat, line);
  }
};

// Function to transform keys
const transformKey = (key, value) => {
  if (value.includes('+') && key.startsWith('player_')) {
    // Special handling for 'touchdowns' and 'yards'
    const specialWords = ['touchdowns', 'yards'];
    let parts = key.substring(7).split('_');
    let transformedParts = [];

    for (let i = 0; i < parts.length; i++) {
      transformedParts.push(parts[i]);
      // Add '+' if the next part is not a special word and the current part is not a special word
      if (
        i < parts.length - 1 &&
        !specialWords.includes(parts[i + 1]) &&
        !specialWords.includes(parts[i])
      ) {
        transformedParts.push('+');
      }
    }

    return 'player_' + transformedParts.join('_');
  }
  return key;
};

export const formatPlayerStats = sport => {
  const sportStats = statMapping[sport];
  const gameStatsMap = sportsStatToOddsMap[sport];
  let formattedStats = [];

  for (const [key, value] of Object.entries(sportStats)) {
    // Transform the key if the value contains '+'
    const transformedKey = transformKey(key, value);

    // Find the corresponding game stat key in the gameStatsMap
    const gameStatKey = Object.keys(gameStatsMap).find(
      gameKey => gameStatsMap[gameKey] === value,
    );

    formattedStats.push({
      value: transformedKey, // Use the transformed key as the value
      stat: value,
      gameStat: gameStatKey || '', // Set the corresponding game stat key or an empty string if not found
    });
  }

  return formattedStats;
};

export const formatPlayerAbbStats = player => {
  // Retrieve the abbreviation map for the player's sport
  const abbrevMap = sportsStatToAbbrevMap[player.sport.id];
  let formattedAbbrevStats = [];

  // Loop through the abbreviation map and create an array of formatted stats
  for (const [statKey, abbrev] of Object.entries(abbrevMap)) {
    formattedAbbrevStats.push({
      stat: statKey, // Original stat key
      display: abbrev, // Abbreviation for the stat
    });
  }

  return formattedAbbrevStats;
};

/*
export const getTopStatsForPosition = (sport, position, stats) => {
  if (sport === "football") {
    switch (position) {
      case "QB":
        return ['stat_passing_yards', 'stat_passing_touchdowns', 'stat_passing_qb_rating', 'stat_passing_sacks'];
      case "S":
        return ['stat_interceptions', 'stat_tackles', 'stat_defensive_passes_defended', 'stat_defensive_qb_hits'];
      case "G":
      case "OT":
      case "C":
        return ['stat_fumbles_recovered', 'stat_touchbacks', 'stat_rushing_yards_per_rush_attempt', 'stat_fumbles'];
      case "WR":
        return ['stat_receptions', 'stat_receiving_yards', 'stat_receiving_touchdowns', 'stat_receiving_targets'];
      case "DT":
      case "DE":
        return ['stat_tackles', 'stat_sacks', 'stat_defensive_tackles_for_loss', 'stat_defensive_qb_hits'];
      case "CB":
        return ['stat_interceptions', 'stat_tackles', 'stat_defensive_passes_defended', 'stat_fumbles_recovered'];
      case "RB":
        return ['stat_rushing_yards', 'stat_rushing_touchdowns', 'stat_receptions', 'stat_fumbles'];
      case "LB":
        return ['stat_tackles', 'stat_sacks', 'stat_interceptions', 'stat_defensive_passes_defended'];
      case "TE":
        return ['stat_receptions', 'stat_receiving_yards', 'stat_receiving_touchdowns', 'stat_fumbles'];
      default:
        return [];
    }
  } else if (sport === "basketball") {
    switch (position) {
      case "C":
        return ['stat_points', 'stat_total_rebounds', 'stat_blocks', 'stat_field_goals_attempted'];
      case "SF":
      case "F":
        return ['stat_points', 'stat_assists', 'stat_total_rebounds', 'stat_steals'];
      case "G":
      case "SG":
      case "PG":
        return ['stat_points', 'stat_assists', 'stat_steals', 'stat_three_point_field_goals_made'];
      case "PF":
        return ['stat_points', 'stat_total_rebounds', 'stat_field_goals_made', 'stat_blocks'];
      default:
        return [];
    }
  } else if (sport === "baseball") {
    switch (position) {
      case "P":
        return ['stat_strikeouts', 'stat_earned_runs', 'stat_innings_pitched', 'stat_walks_allowed'];
      case "3B":
      case "SS":
      case "2B":
        return ['stat_hits_allowed', 'stat_walks_allowed', 'stat_home_runs_allowed', 'stat_strikeouts'];
      case "C":
        return ['stat_hits_allowed', 'stat_earned_runs', 'stat_strikeouts', 'stat_walks_allowed'];
      case "DH":
        return ['stat_hits_allowed', 'stat_home_runs_allowed', 'stat_walks_allowed', 'stat_strikeouts'];
      case "CF":
      case "LF":
      case "RF":
        return ['stat_hits_allowed', 'stat_earned_runs', 'stat_strikeouts', 'stat_walks_allowed'];
      default:
        return [];
    }
  } else if (sport === "hockey") {
    switch (position) {
      case "C":
        return ['stat_shifts', 'stat_goals', 'stat_assists', 'stat_faceoff_percent', 'stat_hits'];
      case "LW":
      case "RW":
        return ['stat_goals', 'stat_assists', 'stat_shots_on_goal', 'stat_hits'];
      case "D":
        return ['stat_blocked_shots', 'stat_takeaways', 'stat_giveaways', 'stat_hits'];
      case "G":
        return ['stat_goals', 'stat_assists', 'stat_penalties', 'stat_hits'];
      default:
        return [];
    }
  }
  return [];
}
*/
export function findNewestOdds(stat, player_odds, sport) {
  // Filter the odds to only include those with the correct market
  const relevantOdds = player_odds.filter(
    odds => odds.market_id === stat && odds.is_main,
  );

  // If there are no relevant odds, return null
  if (relevantOdds.length === 0) {
    return null;
  }

  // Find the odds object with the latest timestamp
  const newestOdd = relevantOdds.reduce(
    (latest, odds) =>
      new Date(latest.timestamp) > new Date(odds.timestamp) ? latest : odds,
    relevantOdds[0],
  );

  // Find the corresponding 'over' or 'under' odd with the same bet_points as the newest odd
  const oppositeLine = newestOdd.selection_line === 'over' ? 'under' : 'over';
  const correspondingOdd = relevantOdds.find(
    odds =>
      odds.points === newestOdd.points &&
      odds.selection_line === oppositeLine,
  );

  // Return both the newest odd and the corresponding odd
  return [newestOdd, correspondingOdd].filter(Boolean); // filter out any undefined or null values
}

export const determineOpponentTeam = (playerTeamName, gameScore) => {
  return gameScore?.home_team_display === playerTeamName
    ? gameScore?.away_team_display
    : gameScore?.home_team_display;
};

export const calculateComboStats = (data, comboStatKey) => {
  // Split the comboStatKey into individual stats
  const stats = comboStatKey
    .split('_')
    .filter(part => !['stat', 'plus'].includes(part));
  // Adjust the individual stats for special words
  const adjustedStats = stats.flatMap(stat => {
    if (['touchdowns', 'yards'].includes(stat)) {
      // If the stat is a special word, append it to each previous stat in the array
      return stats.slice(0, -1).map(prevStat => `${prevStat}_${stat}`);
    } else {
      // If it's not a special word, return the stat as is
      return [stat];
    }
  });

  // Calculate the sum for each stat and add it to the corresponding game sum
  return data.map(gameData => {
    return adjustedStats.reduce((sum, stat) => {
      const fullStatKey = `${stat}`;
      // For rebounds, use 'stat_total_rebounds' instead of 'stat_rebounds'
      const statKey = stat === 'rebounds' ? 'total_rebounds' : fullStatKey;
      return sum + (gameData?.stats[0].stats[statKey] || 0);
    }, 0);
  });
};

// Function to convert "time on ice" strings to minutes
export const timeToMinutes = timeString => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes + seconds / 60;
};
