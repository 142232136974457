import {useNavigate} from 'react-router-dom';
import {formatPlayerAbbStats} from '../../../utils/sportStats/playerStats';
import {useEffect, useState} from 'react';
import {Loading} from '../../../components/LoadingComponent';

export const PlayerBoxScores = ({playersData, homeTeam, awayTeam, data}) => {
  const navigate = useNavigate();
  const [formattedStats, setFormattedStats] = useState([]);
  const homePlayers = playersData?.homePlayers?.playersWithResults?.players
  const awayPlayers = playersData?.awayPlayers?.playersWithResults?.players

  useEffect(() => {
    if (homePlayers?.length > 0) {
      setFormattedStats(
        formatPlayerAbbStats(homePlayers[0].playerDetails),
      );
    }
  }, [playersData]);

  const generateStatMap = stats => {
    let mapArray = [];
    stats.forEach(stat => {
      mapArray.push({
        stat: stat?.stat,
        display: stat?.display,
      });
    });
    return mapArray;
  };

  const statMap = generateStatMap(formattedStats);

  if (!playersData || homePlayers?.length < 0) {
    return (
      <div className="content h-screen w-screen flex items-center justify-center bg-white animate-bg">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full bg-white p-4 drop-shadow-sm gap-8">
      <div className="flex flex-col">
        <div className="flex mb-3">
          <img src={awayTeam.logo} alt="logo" className="w-6 h-6 mr-2" loading="lazy"/>
          <span className="text-lg font-bold">{awayTeam.name}</span>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-slate-200">
              <tr>
                <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
                  Player
                </th>
                <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
                  GP
                </th>
                {statMap &&
                  statMap.map((stat, index) => (
                    <th
                      key={index}
                      className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center"
                    >
                      {stat.display}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {playersData &&
                awayPlayers?.length > 0 &&
                awayPlayers
                  ?.filter((player) =>
                    {
											return player.playerResults.some(
												result => result?.fixture.id === data.gameScore.id,
											)
										}
                  )
                  .map((player, index) => {
                    const gameResult = player.playerResults.find(
                      result => result.fixture.id  === data.gameScore.id,
                    );
                    return (
                      <tr
                        key={index}
                        onClick={() =>
                          navigate(
                            `/sports/playerInfo/${player.playerDetails.id}`,
                          )
                        }
                        className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-150`}
                      >
                        <td className="px-1 py-1 text-sm sm:text-base player-profile-td">
                          <div className="flex items-center gap-3">
                            <img
                              className="w-10 h-10 object-cover"
                              src={
                                player.playerDetails.logo ||
                                'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'
                              }
                              alt="Player"
                              loading="lazy"
                            />
                            <span>{player.playerDetails.name}</span>
                          </div>
                        </td>
                        <td className="text-center text-sm sm:text-base">
                          {(player?.playerResults).length}
                        </td>
                        {statMap.map((stat, statIndex) => (
                          <td
                            key={statIndex}
                            className="text-center text-sm sm:text-base"
                          >
                            {gameResult.stats[0].stats[stat.stat]}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="flex mb-3">
          <img src={homeTeam.logo} alt="logo" className="w-6 h-6 mr-2" loading="lazy"/>
          <span className="text-lg font-bold">{homeTeam.team_name}</span>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="min-w-full">
            <thead className="bg-slate-200">
              <tr>
                <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
                  Player
                </th>
                <th className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center">
                  GP
                </th>
                {statMap &&
                  statMap.map((stat, index) => (
                    <th
                      key={index}
                      className="px-2 py-2 text-xs sm:text-sm md:text-base font-bold text-center"
                    >
                      {stat.display}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {playersData &&
                homePlayers?.length > 0 &&
                homePlayers
                  ?.filter(player =>
                    player.playerResults.some(
                      result => result?.fixture.id === data.gameScore.id,
                    ),
                  )
                  .map((player, index) => {
                    const gameResult = player.playerResults.find(
                      result => result?.fixture.id === data.gameScore.id,
                    );
                    return (
                      <tr
                        key={index}
                        onClick={() =>
                          navigate(
                            `/sports/playerInfo/${player.playerDetails.id}`,
                          )
                        }
                        className={`cursor-pointer ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors duration-150`}
                      >
                        <td className="px-1 py-1 text-sm sm:text-base player-profile-td">
                          <div className="flex items-center gap-3">
                            <img
                              className="w-10 h-10 object-cover"
                              src={
                                player.playerDetails.logo ||
                                'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'
                              }
                              alt="Player"
                              loading="lazy"
                            />
                            <span>{player.playerDetails.name}</span>
                          </div>
                        </td>
                        <td className="text-center text-sm sm:text-base">
                          {(player?.playerResults).length}
                        </td>
                        {statMap.map((stat, statIndex) => (
                          <td
                            key={statIndex}
                            className="text-center text-sm sm:text-base"
                          >
                          {gameResult.stats[0].stats[stat.stat]}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
