export const statMapping = {
  soccer: {
    player_goals: 'Goals',
    player_total_pass: 'Total Passes',
    player_accurate_pass: 'Accurate Passes',
    player_duel_won: 'Duels Won',
  },
  esports: {
    player_kills: 'Kills',
    player_deaths: 'Deaths',
  },
  football: {
    player_passing_yards: 'Pass Yards',
    player_receiving_yards: 'Receiving Yards',
    player_rushing_yards: 'Rush Yards',
    player_passing_rushing_receiving_touchdowns: 'Pass+Rush+Rec TDs',
    //player_field_goals_made: 'FG Made',
    player_passing_touchdowns: 'Pass TDs',
    player_rushing_receiving_yards: 'Rush+Rec Yards',
    player_receptions: 'Receptions',
    player_passing_attempts: 'Pass Attempts',
    //player_sacks: 'Sacks',
    player_passing_completions: 'Pass Completions',
    //player_interceptions: 'Int',
    player_passing_rushing: 'Pass+Rush Yards',
    player_rushing_attempts: 'Rush Attempts',
    player_kicking_points: 'Kicking Points',
    //player_tackles_assists: 'Tackles+Ast',
  },
  basketball: {
    player_points: 'Points',
    player_rebounds: 'Rebounds',
    player_assists: 'Assists',
    player_points_rebounds_assists: 'Pts+Reb+Asts',
    //player_fantasy_score: "Fantasy Score",
    player_points_rebounds: 'Pts+Reb',
    player_points_assists: 'Pts+Asts',
    player_made_threes: '3-PT Made',
    player_blocks: 'Blocked Shots',
    //player_fouls: 'Fouls',
    player_rebounds_assists: 'Rebs+Asts',
    //player_blocks_steals: 'Blks+Stls',
    player_turnovers: 'Turnovers',
    player_steals: 'Steals',
  },
  baseball: {
    player_strikeouts: 'Strikeouts',
    player_earned_runs: 'Earned Runs',
    player_hits: 'Hits',
    player_walks: 'Walks',
    player_innings_pitched: 'IP',
    player_home_runs: 'Runs',
  },
  hockey: {
    player_time_on_ice: 'Time On Ice',
    player_points: 'Points',
    player_shots_on_goal: 'Shots On Goal',
    player_assists: 'Assists',
    player_hits: 'Hits',
  },
};

export const sportsStatToOddsMap = {
  soccer : {
    goals: 'Goals',
    total_pass: 'Total Passes',
    accurate_pass: 'Accurate Passes',
    duel_won: 'Duels Won',
  },
  esports: {
    kills: 'Kills',
    deaths: 'Deaths',
  },

  basketball: {
    points: 'Points',
    assists: 'Assists',
    blocks: 'Blocked Shots',
    steals: 'Steals',
    turnovers: 'Turnovers',
    total_rebounds: 'Rebounds',
    three_point_field_goals_made: '3-PT Made',
    points_rebounds_assists: 'Pts+Reb+Asts',
    //fantasy_score: "Fantasy Score",
    points_rebounds: 'Pts+Reb',
    points_assists: 'Pts+Asts',
    fouls: 'Fouls',
    total_rebounds_assists: 'Rebs+Asts',
    blocks_steals: 'Blks+Stls',
  },
  football: {
    punts: 'Punts',
    assists: 'Assists',
    passing_yards: 'Pass Yards',
    rushing_yards: 'Rush Yards',
    rush_receiving_touchdowns: 'Rush+Rec TDs',
    receiving_yards: 'Receiving Yards',
    passing_touchdowns: 'Pass TDs',
    rushing_touchdowns: 'Rush TDs',
    receiving_touchdowns: 'Receiving TDs',
    interceptions: 'Int',
    fumbles_recovered: 'Fumbles Recovered',
    sacks: 'Sacks',
    tackles_assists: 'Tackles+Ast',
    defensive_touchdowns: 'Defensive TDs',
    passing_attempts: 'Pass Attempts',
    passing_completions: 'Pass Completions',
    rushing_attempts: 'Rush Attempts',
    kicking_points: 'Kicking Points',
    passing_rushing_yards: 'Pass+Rush Yards',
    passing_rushing_receiving_touchdowns: 'Pass+Rush+Rec TDs',
    rushing_receiving_yards: 'Rush+Rec Yards',
    receptions: 'Receptions',
    field_goals_made: 'FG Made',
  },
  baseball: {
    strikeouts: 'Strikeouts',
    earned_runs: 'Earned Runs',
    hits: 'Hits',
    walks: 'Walks',
    innings_pitched: 'Innings Pitched',
    home_runs: 'Home Runs',
    // Add more mappings as needed
  },
  hockey: {
    hits: 'Hits',
    goals: 'Points',
    assists: 'Assists',
    blocked_shots: 'Blocked Shots',
    shots_on_goal: 'Shots On Goal',
    time_on_ice: 'Time On Ice',
    // Additional mappings can be added as needed
  },
};

export const sportsStatToAbbrevMap = {
  soccer: {
    goals: 'G',
    total_pass: 'TP',
    accurate_pass: 'AP',
    duel_won: 'DW',
  },
  esports: {
    kills: 'KLS',
    deaths: 'DTHS',
  },
  basketball: {
    points: 'PTS',
    assists: 'AST',
    blocks: 'BLK',
    steals: 'STL',
    turnovers: 'TO',
    total_rebounds: 'REB',
    three_point_field_goals_made: '3PM',
    fouls: 'F',
    minutes: 'MIN',
  },
  football: {
    punts: 'PNT',
    assists: 'AST',
    passing_yards: 'PYD',
    rushing_yards: 'RYD',
    receiving_yards: 'REY',
    passing_touchdowns: 'PTD',
    rushing_touchdowns: 'RTD',
    receiving_touchdowns: 'RETD',
    interceptions: 'INT',
    fumbles_recovered: 'FR',
    sacks: 'SK',
    tackles_assists: 'TKL',
    defensive_touchdowns: 'DT',
    passing_attempts: 'PAT',
    passing_completions: 'PC',
    rushing_attempts: 'RA',
    kicking_points: 'KP',
    receptions: 'REC',
    field_goals_made: 'FGM',
  },
  baseball: {
    strikeouts: 'SO',
    earned_runs: 'ER',
    hits: 'H',
    walks: 'BB',
    innings_pitched: 'IP',
    home_runs: 'HR',
  },
  hockey: {
    hits: 'HIT',
    goals: 'G',
    assists: 'A',
    blocked_shots: 'BS',
    shots_on_goal: 'SOG',
  },
};
