import React, { useState } from 'react';
import '../../../styles/login/loginStyle.css';
import { icons } from '../../../assets';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/actions/UserActions';
import { emailPattern, phonePattern } from '../../../utils/regexUtils';

export const LoginForm = () => {
  const [password, setPassword] = useState('');
  const [input, setInput] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin);
  const { error } = userLogin;

  const submitHandler = e => {
    e.preventDefault();

    // Determine if the input is an email or a phone number directly
    const isEmail = emailPattern.test(input);
    const isPhone = phonePattern.test(input);

    if (!isEmail && !isPhone) {
      console.error('Invalid Email');
      return;
    }

    // Dispatch the login action with the evaluated input and password
    if (password) {
      const lowerCaseInput = input.toLowerCase(); // Convert input to lowercase
      dispatch(
        login(
          isEmail ? lowerCaseInput : null,
          password,
          isPhone ? lowerCaseInput : null,
          navigate,
        ),
      );
    } else {
      console.debug('Password is missing');
    }
  };

  return (
    <div className="SignUp flex w-screen h-screen overflow-x-hidden">
      <div className="LeftSide w- flex-1 flex-col items-center justify-center bg-neutral-800 text-white md:flex hidden">
        <img className="mb-6" src={icons.logo} alt="Brand illustration" loading="lazy"/>
        <h2 className="BuySellEachDigi text-white text-3xl font-bold mb-4">
          Connect. Win. Repeat.
        </h2>
        <p className="EasilyBuyBitcoinA text-center w-[400px] text-white text-sm font-semibold">
          Easily check betting lines.
        </p>
      </div>

      <div className="Form flex flex-1 flex-col items-center justify-center bg-neutral-50 h-screen">
        <div className="w-2/4 pt-10 h-1/3">
          <h1 className="StartYourCryptoIn text-center text-neutral-900 text-2xl font-bold">
            Sign In
          </h1>
        </div>
        <div className="Social mt-6 w-2/4 h-1/3">
          {error && <div className="text-red mb-4">{error}</div>}
          <form className="mb-4" onSubmit={submitHandler}>
            <div className="mb-6 md:w-full">
              <label
                htmlFor="input"
                className="w-36 h-6 text-zinc-500 text-xs font-medium leading-none"
              >
                EMAIL
              </label>
              <input
                onChange={e => setInput(e.target.value)}
                onInput={e => setInput(e.target.value)}
                value={input}
                className="w-full p-2 h-12 rounded-xl border border-lightGray focus:shadow-outline"
                type="text"
                name="input"
                id="input"
              />
            </div>
            <div className="mb-8 md:w-full">
              <label
                htmlFor="password"
                className="w-36 h-6 text-zinc-500 text-xs font-medium leading-none"
              >
                PASSWORD
              </label>
              <input
                onChange={e => setPassword(e.target.value)}
                onInput={e => setPassword(e.target.value)}
                value={password}
                className="w-full p-2 h-12 rounded-xl border border-lightGray focus:shadow-outline"
                type="password"
                name="password"
                id="password"
              />
            </div>
            <Link
              to="/forgot-password"
              className="text-center text-zinc-500 text-sm font-semibold"
            >
              Forgot password?
            </Link>
            <button
              type="submit"
              className="bg-primary rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold w-full"
            >
              Sign In
            </button>
          </form>
        </div>
        <div className="mt-16 mb-5 w-2/4 h-1/3">
          <p className="AlreadyHaveAnAcco mt-4 text-center text-zinc-500 text-sm font-semibold">
            Don't have an account?
          </p>
          <Link
            to="/register"
            className="SignInButton w-full bg-neutral-900 text-white rounded-xl mt-4 flex justify-center items-center h-[48px] p-5 text-sm font-semibold"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </div>
  );
};
