// import axiosInstance from "../../core/axios/axiosInstance";

// const getTeams = async (sport, league) => {
//   const response = await axiosInstance.get('/sports/top-teams', { params: { sport, league } });
//   return response.data;
// };

// const getTeam = async (teamId) => {
//   const response = await axiosInstance.get('/sports/team', { params: { teamId } });
//   return response.data;
// };

// const getOdds = async (sport, league) => {
//   const response = await axiosInstance.get('/sports/upcoming-odds', { params: { sport, league } });
//   return response.data;
// };

// const getGameOdds = async (gameId) => {
//   const response = await axiosInstance.get('/sports/game-odds', { params: { gameId } });
//   return response.data;
// };

// const getTeamStats = async (sport, league, team) => {
//   const response = await axiosInstance.get('/sports/team-stats', { params: { sport, league, team } });
//   return response.data;
// };

// const getTeamPlayers = async (sport, league, teamId) => {
//   const response = await axiosInstance.get('/sports/team-players', { params: { sport, league, teamId } });
//   return response.data;
// };

// const getPlayer = async (playerId, noOdds, userId) => {
//   const response = await axiosInstance.get('/sports/player', { params: { playerId, noOdds, userId } });
//   return response.data;
// };

// const getPropBetsPlayers = async (teamId, playerIds, userId) => {
//   const response = await axiosInstance.get('/sports/prop-bets-players', { params: { teamId, playerIds, userId } });
//   return response.data;
// };

// const getTeamSchedule = async (sport, league, team) => {
//   const response = await axiosInstance.get('/sports/team-schedule', { params: { sport, league, team } });
//   return response.data;
// };

// const getSchedule = async (sport, league) => {
//   const response = await axiosInstance.get('/sports/schedule', { params: { sport, league } });
//   return response.data;
// };

// const getPropBets = async (gameId) => {
//   const response = await axiosInstance.get('/sports/prop-bets', { params:{gameId} });
//   return response.data;
// };

// const getLeagues = async (sport) => {
//   const response = await axiosInstance.get('/sports/sport-leagues', { params: { sport } });
//   return response.data;
// };

// const getLiveScores = async (sport, league, game_id) => {
//   const response = await axiosInstance.get('/sports/live-scores', { params: { sport, league, game_id } });
//   return response.data;
// };

// const getLiveOdds = async (sport, league, game_id) => {
//   const response = await axiosInstance.get('/sports/live-odds', { params: { sport, league, game_id } });
//   return response.data;
// };

// const getPlayerOdds = async (sport, league, playerId) => {
//   const response = await axiosInstance.get('/sports/player-ev', { params: { sport, league, playerId } });
//   return response.data;
// }

// const getUpcomingMatchups = async (sport, league) => {
//   const response = await axiosInstance.get('/sports/upcoming-matchups', { params: { sport, league } });
//   return response.data;
// }

// const getRandomUpcomingMatchups = async () => {
//   const response = await axiosInstance.get('/sports/random-upcoming-matchups');
//   return response.data;
// }

// const searchSports = async (topic) => {
//   const response = await axiosInstance.get('/sports/sports-search', { params: { topic } });
//   return response.data;
// }


// export const sportsService = {
//   getTeams,
//   getTeam,
//   getOdds,
//   getGameOdds,
//   getTeamStats,
//   getTeamPlayers,
//   getPlayer,
//   getPropBetsPlayers,
//   getTeamSchedule,
//   getSchedule,
//   getPropBets,
//   getLeagues,
//   getLiveScores,
//   getLiveOdds,
//   getPlayerOdds,
//   getUpcomingMatchups,
//   getRandomUpcomingMatchups,
//   searchSports,
// }




// trying to add get league odds
import axiosInstance from "../../core/axios/axiosInstance";

const getTeams = async (sport, league) => {
  const response = await axiosInstance.get('/sports/top-teams', { params: { sport, league } });
  return response.data;
};

const getTeam = async (teamId) => {
  const response = await axiosInstance.get('/sports/team', { params: { teamId } });
  return response.data;
};

const getOdds = async (sport, league) => {
  const response = await axiosInstance.get('/sports/upcoming-odds', { params: { sport, league } });
  return response.data;
};

const getGameOdds = async (gameId) => {
  const response = await axiosInstance.get('/sports/game-odds', { params: { gameId } });
  return response.data;
};

const getTeamStats = async (sport, league, team) => {
  const response = await axiosInstance.get('/sports/team-stats', { params: { sport, league, team } });
  return response.data;
};

const getTeamPlayers = async (sport, league, teamId) => {
  const response = await axiosInstance.get('/sports/team-players', { params: { sport, league, teamId } });
  return response.data;
};

const getPlayer = async (playerId, noOdds, userId) => {
  const response = await axiosInstance.get('/sports/player', { params: { playerId, noOdds, userId } });
  return response.data;
};

const getPlayerResultWithScore = async (playerId) => {
  const response = await axiosInstance.get('/sports/player-score', { params: { playerId } });
  return response.data;
};

const getUpcomingPlayerGameOdds = async(sport, league, playerId, team, teamId) => {
  const response = await axiosInstance.get('/sports/player-game-odds', { params: { sport, league, playerId, team, teamId } });
  return response.data;
};

const getPropBetsPlayers = async (teamId, playerIds, userId, sport) => {
  const response = await axiosInstance.get('/sports/prop-bets-players', { params: { teamId, playerIds, userId,sport } });
  return response.data;
};

const getTeamSchedule = async (sport, league, team) => {
  const response = await axiosInstance.get('/sports/team-schedule', { params: { sport, league, team } });
  return response.data;
};

const getSchedule = async (sport, league) => {
  const response = await axiosInstance.get('/sports/schedule', { params: { sport, league } });
  return response.data;
};

const getPropBets = async (gameId) => {
  const response = await axiosInstance.get('/sports/prop-bets', { params: { gameId } });
  return response.data;
};
const getPropBetsGame = async (gameId) => {
  const response = await axiosInstance.get('/sports/prop-bets-game', { params: { gameId } });
  return response.data;
};

const getLeagues = async (sport) => {
  const response = await axiosInstance.get('/sports/sport-leagues', { params: { sport } });
  return response.data;
};

const getLiveScores = async (sport, league, fixture_id) => {
  const response = await axiosInstance.get('/sports/live-scores', { params: { sport, league, fixture_id } });
  return response.data;
};

const getLiveOdds = async (sport, league, game_id) => {
  const response = await axiosInstance.get('/sports/live-odds', { params: { sport, league, game_id } });
  return response.data;
};

const getPlayerOdds = async (sport, league, playerId) => {
  const response = await axiosInstance.get('/sports/player-ev', { params: { sport, league, playerId } });
  return response.data;
};

const getUpcomingMatchups = async (sport, league, daysFromNow) => {
  const response = await axiosInstance.get('/sports/upcoming-matchups', { params: { sport, league,daysFromNow } });
  return response.data;
};

const getRandomUpcomingMatchups = async () => {
  const response = await axiosInstance.get('/sports/random-upcoming-matchups');
  return response.data;
};

const searchSports = async (topic) => {
  const response = await axiosInstance.get('/sports/sports-search', { params: { topic } });
  return response.data;
};

const getLeagueOdds = async (league, date, oddsType, sportsbooks) => {
  // console.log('Fetching from API for league odds with params:', { league, date, oddsType, sportsbooks });
  const response = await axiosInstance.get('/sports/league-odds', { params: { league, date, oddsType, sportsbooks } });
  return response.data;
};


export const sportsService = {
  getTeams,
  getTeam,
  getOdds,
  getGameOdds,
  getTeamStats,
  getTeamPlayers,
  getPlayer,
  getPropBetsPlayers,
  getTeamSchedule,
  getSchedule,
  getPropBets,
  getLeagues,
  getLiveScores,
  getLiveOdds,
  getPlayerOdds,
  getUpcomingMatchups,
  getRandomUpcomingMatchups,
  searchSports,
  getLeagueOdds,
  getPropBetsGame,
  getPlayerResultWithScore,
  getUpcomingPlayerGameOdds
};
