import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Dashboard/dashboardStyle.css';
import icons from '../assets/icons';
import { searchNews } from '../redux/actions/NewsActions';
import { searchSports } from '../redux/actions/SportsActions';
import { TryFreeButton } from './TryFreeButton';
import { BackButton } from './BackButton';
import { NotificationsModal } from '../components/modals/NotificationsModal';
import { GetPremiumModal } from '../components/modals/GetPremiumModal';
import { SidebarModal } from '../components/modals/SidebarModal';
import { FeedbackModal } from '../components/modals/FeedbackModal';
import { logout } from '../redux/actions/UserActions';

export const Navbar = ({ title, saveInterests, darkMode }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTab, setSelectedTab] = useState('All');
  const [isNotificationsModalVisible, setNotificationsModalVisible] = useState(false);
  const [isPremiumModalVisible, setPremiumModalVisible] = useState(false);
  const [isProfileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [isSupportDropdownVisible, setSupportDropdownVisible] = useState(false);
  const [isSidebarModalVisible, setSidebarModalVisible] = useState(false);
  const [isFeedbackModalVisible, setFeedbackModalVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const latestNews = useSelector((state) => state.latestNews.latest);
  const searchedNews = useSelector((state) => state.searchNews.search);
  const searchedSports = useSelector((state) => state.searchSports?.sport);

  const debounceTimeoutRef = useRef(null);

  const userDetails = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null;

  const renderContent = () => {
    switch (selectedTab) {
      case 'All':
        return (
          <SearchContent
            news={latestNews?.news?.slice(0, 5)}
          />
        );
      case 'Search':
        return (
          <SearchContent
            news={searchedNews?.news}
            searchedSports={searchedSports}
          />
        );
      default:
        return null;
    }
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    clearTimeout(debounceTimeoutRef.current);
    debounceTimeoutRef.current = setTimeout(() => {
      dispatch(searchNews(searchTerm));
      dispatch(searchSports(searchTerm));
      setSelectedTab('Search');
    }, 1000);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleProfileClick = () => {
    navigate(`/profile/${userDetails.id}`);
  };

  const handleSupportClick = () => {
    setSupportDropdownVisible((prev) => !prev);
  };

  const handleFeedbackClick = () => {
    setFeedbackModalVisible(true);
  };

  const handleOptionsClick = () => {
    setSidebarModalVisible((prev) => !prev);
  };

  const handleGetPremiumClick = () => {
    setProfileDropdownVisible(false);
    setPremiumModalVisible(true);
  };

  const handleLogout = async () => {
    await dispatch(logout(navigate));
  };

  const dropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
      if (profileDropdownRef.current && !profileDropdownRef.current.contains(event.target)) {
        setProfileDropdownVisible(false);
        setSupportDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isSidebarModalVisible || isNotificationsModalVisible || isPremiumModalVisible || isFeedbackModalVisible) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isSidebarModalVisible, isNotificationsModalVisible, isPremiumModalVisible, isFeedbackModalVisible]);

  return (
    <div className={`Navbar w-full xs:h-24 ${darkMode ? 'text-white' : 'text-black'} flex`}>
      <div className="OptionsButton sm:hidden" onClick={handleOptionsClick}>
        <img src={darkMode ? icons.menuLight : icons.menu} alt="Options" className="" />
      </div>
      <div className="relative flex items-center ">
      <div className="Backbutton">
        <BackButton />
      </div>
        <div className="hidden sm:flex items-center bg-white ml-60 rounded-xl border border-gray sm:w-64 h-8 px-2 SearchField relative">
          <div className="clearfix">
            <div className="SearchIcon w-4 h-4 mr-2 absolute top-0 bottom-0 left-[10px] flex items-center justify-center m-auto">
              <img className="Shape object-contain object-center" src={icons.search} alt="img" loading="lazy" />
            </div>
          </div>
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearch}
            onClick={toggleDropdown}
            placeholder="Search for Games, Teams, Players"
            className="SearchByCollection text-zinc-400 text-xs font-semibold leading-none bg-transparent outline-none w-full pl-[30px]"
          />
        </div>
        {showDropdown && (
          <div ref={dropdownRef} className="search-dropdown absolute top-full mt-2 w-96 bg-white rounded-lg border border-black border-opacity-10 z-99">
            <div className="w-full h-96 bg-white rounded-lg border border-black border-opacity-10 flex flex-col p-4 overflow-hidden">
              <div className="Tabs text-center flex items-center justify-center mt-4">
                <p onClick={() => setSelectedTab('All')} className={`text-sm font-normal cursor-pointer flex-grow ${selectedTab === 'All' ? 'text-slate-700 font-semibold border-b-2 border-slate-700' : 'text-black'}`}>All</p>
                <p onClick={() => setSelectedTab('Sports')} className={`text-sm font-normal cursor-pointer flex-grow ${selectedTab === 'Sports' ? 'text-slate-700 font-semibold border-b-2 border-slate-700' : 'text-black'}`}>Sports</p>
                <p onClick={() => setSelectedTab('News')} className={`text-sm font-normal cursor-pointer flex-grow ${selectedTab === 'News' ? 'text-slate-700 font-semibold border-b-2 border-slate-700' : 'text-black'}`}>News</p>
                <p onClick={() => setSelectedTab('Articles')} className={`text-sm font-normal cursor-pointer flex-grow ${selectedTab === 'Articles' ? 'text-slate-700 font-semibold border-b-2 border-slate-700' : 'text-black'}`}>Articles</p>
              </div>
              {renderContent()}
            </div>
          </div>
        )}
      </div>
      <Link to="/dashboard" className="header-logo-wrapper">
        <img
          className="LogoHorWhiteColor sm:w-30 w-20 h-auto"
          src={darkMode ? icons.bigLogoLight : icons.bigLogoDark}
          alt="Logo"
          loading="lazy"
        />
      </Link>
      <div className="relative flex items-center">
        {userDetails ? (
          <TryFreeButton />
        ) : (
          <Link to="/register" className="SignUpBtn sticky bg-primary text-black h-12 rounded-lg p-4 flex items-center space-x-2">
            <span className="text-xl font-bold">Sign Up</span>
          </Link>
        )}
        {!userDetails ? (
          <div className="Login">
            <button className="flex items-center justify-center w-full h-12 bg-primary rounded-lg transform" onClick={() => navigate('/login')}>
              <span className="text-xl text-black font-bold">Login</span>
            </button>
          </div>
        ) : (
          <div className="Profile">
            <button onClick={() => setProfileDropdownVisible((prev) => !prev)} className="relative flex items-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              <img className="icon-size w-8 h-8 rounded-full" loading="lazy" src={userDetails.profileImg || icons.defaultProfile} alt="Profile" />
            </button>
            {isProfileDropdownVisible && (
              <div ref={profileDropdownRef} className="profile-dropdown absolute right-0 mt-2 w-30 bg-white rounded-md shadow-lg z-90" style={{ top: 'calc(100% + 8px)' }}>
                <button className="profile-options flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90" onClick={handleProfileClick}>
                  <span className=" text-base text-black">Profile</span>
                </button>
                <button className="profile-options flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90" onClick={handleSupportClick}>
                  <span className=" text-base text-black">Support</span>
                </button>
                <button className="profile-options flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90" onClick={handleGetPremiumClick}>
                  <span className=" text-base text-black">Get Premium</span>
                </button>
                <button className="profile-options flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90" onClick={handleLogout}>
                  <span className=" text-base text-black">Log Out</span>
                </button>
              </div>
            )}
            {isSupportDropdownVisible && (
              <div ref={profileDropdownRef} className="support-dropdown">
                <a href="https://app.termly.io/document/terms-of-service/5ccba1c0-0867-4fa6-9364-3154d0680c7a" className="flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90">
                  <span className="text-base text-black">Terms of Service</span>
                </a>
                <a href="https://app.termly.io/document/privacy-policy/02f975f8-f0b2-4a39-8497-749ba970910a" className="flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90">
                  <span className="text-base text-black">Privacy Policy</span>
                </a>
                <button className="flex items-center w-full px-4 py-2 text-left hover:bg-primary z-90" onClick={handleFeedbackClick}>
                  <span className="text-base text-black">Feedback</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {title === 'Getting started' && (
        <div className="flex space-x-4">
          <button onClick={() => saveInterests()} className="flex items-center justify-center bg-neutral-900 rounded-xl px-4 py-1">
            <div className="Group156 w-4 h-4 mr-2.5">
              <img className="CombinedShape299" src={icons.save} alt="img" loading="lazy" />
            </div>
            <p className="SaveFavorites text-center text-white text-sm font-semibold leading-tight">Save Favorites</p>
          </button>
          <button className="flex items-center justify-center bg-lightGray rounded-xl px-4 py-1">
            <p className="Skip text-center text-neutral-900 text-sm font-semibold leading-tight">Skip</p>
          </button>
        </div>
      )}
      <NotificationsModal isVisible={isNotificationsModalVisible} onClose={() => setNotificationsModalVisible(false)} />
      <GetPremiumModal isVisible={isPremiumModalVisible} onClose={() => setPremiumModalVisible(false)} />
      {isSidebarModalVisible && <SidebarModal onClose={handleOptionsClick} />}
      <FeedbackModal isVisible={isFeedbackModalVisible} onClose={() => setFeedbackModalVisible(false)} />
    </div>
  );
};

const SearchContent = ({ news, searchedSports }) => {
  return (
    <div className="h-[calc(100vh-20px)] flex flex-col overflow-y-auto">
      {searchedSports && searchedSports?.players.length > 0 && (
        <div>
          <div className="text-black text-sm font-bold my-3">Players</div>
          {searchedSports?.players.map((item, index) => (
            <Link to={`/sports/playerInfo/${item.id}`} key={index} className="flex items-center justify-between gap-4 mb-3">
              <img className="w-6 h-6" src={icons.profile} alt="News" loading="lazy" />
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.player_name}</p>
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.team_name}</p>
              <img className="w-4 h-4 rounded-full" src={icons.chevronRight} alt="Crypto" loading="lazy" />
            </Link>
          ))}
        </div>
      )}
      {searchedSports && searchedSports?.teams.length > 0 && (
        <div>
          <div className="text-black text-sm font-bold my-3">Teams</div>
          {searchedSports?.teams.map((item, index) => (
            <Link to={`/sports/team/${item.id}`} key={index} className="flex items-center justify-between gap-4 mb-3">
              <img className="w-8 h-6" src={icons.team} alt="News" loading="lazy" />
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.team_name}</p>
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.league}</p>
              <img className="w-4 h-4 rounded-full" src={icons.chevronRight} alt="Crypto" />
            </Link>
          ))}
        </div>
      )}
      {searchedSports && searchedSports?.games.length > 0 && (
        <div>
          <div className="text-black text-sm font-bold my-3">Games</div>
          {searchedSports?.games.map((item, index) => (
            <Link to={`/sports/propBets/${item.id}`} key={index} className="flex items-center justify-between gap-4 mb-3">
              <img className="w-6 h-6" src={icons.game} alt="News" loading="lazy" />
              <p className="text-neutral-900 text-base font-normal flex-grow">
                {item.home_team_abb} vs {item.away_team_abb}
              </p>
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.league}</p>
              <img className="w-4 h-4 rounded-full" src={icons.chevronRight} alt="Crypto" loading="lazy" />
            </Link>
          ))}
        </div>
      )}
      {news && news.length > 0 && (
        <div>
          <div className="text-black text-sm font-bold my-3">News</div>
          {news.map((item, index) => (
            <Link to={item.Url} key={index} className="flex items-center justify-between gap-4 mb-3">
              <img className="w-6 h-6" src={icons.newspapers} alt="News" loading="lazy" />
              <p className="text-neutral-900 text-base font-normal flex-grow">{item.Title}</p>
              <img className="w-4 h-4 rounded-full" src={icons.chevronRight} alt="Crypto" loading="lazy" />
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
