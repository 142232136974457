import React from 'react';
import { Link } from 'react-router-dom';

const topNCAAFTeamsIds = [
  '83E0960213F5',
  '63F0FB69A8FC',
  '7DBBEB676B16',
  '04C37B5D7034',
  'B96E97F501AB',
  '36D5C55CF7F1',
  'D836AC0A0496',
  '11BE241A8FAF',
  'FD8B7660E58B',
  '647B0C1D6483',
  'D4D329E885F8',
  'FCFDE0201BF2',
  '3BF603A68A5F',
  '7D4F46307D8C',
  'EEEB0AC066A7',
  '666C6E0DDD96',
  'AC988BB100C6',
  'D7CBAF5F03AE',
  'DF83441F8DF0',
  'AB8D1BDC0E39',
  'F68617FF3A0D',
  'AFF084D95AB6',
  'CAC97D0AA1EE',
  '9F321A1DA403',
  '638274B79CED',
];

const topNCAABTeamsIds = [
  '35F3FED81BC2',
  '8318BBF6A210',
  '00CC7D9B5E8B',
  '0F508660B269',
  '99BB7AC6B130',
  '79F6D0732A24',
  '7261410FBA2F',
  '39CF050AF563',
  'C9C7C51488CD',
  '4FE24B0DFF4C',
  '9C9950C85A05',
  '136DE7EDAFD8',
  'E30336717A46',
  '0E94D44D0C77',
  '451E32D002D6',
  '034E4554EAA3',
  '9C6BA5B34989',
  'CEECFD37686A',
  '0D0C4B1833DD',
  'DB6937075EE8',
  '48F80E5732CA',
];

const TeamsCards = ({ sportsData, title, darkMode }) => {
  // Filtering based on title and respective IDs
  let filteredSportsData = sportsData;
  if (title === 'NCAAF') {
    filteredSportsData = sportsData.filter(sport =>
      topNCAAFTeamsIds.includes(sport.id),
    );
  } else if (title === 'NCAAB') {
    filteredSportsData = sportsData.filter(sport =>
      topNCAABTeamsIds.includes(sport.id),
    );
  }

  return (
    <div className="w-full mb-10">
      <div className={`text-xl font-semibold mb-2 ${darkMode ? 'text-white' : 'text-black'} `}>{title}</div>
      <div className="grid grid-cols-3 md:grid-cols-2 xl:grid-cols-3 gap-4">
        {filteredSportsData.map((sport, index) => {
          // Skip rendering images for golf teams to prevent 404 errors
          const cardContent = sport.sport === 'golf' ? (
            <span className="mt-2 text-zinc-800 text-xs font-normal leading-tight">
              {sport.team_name || 'PGA Tournament'}
            </span>
          ) : (
            <>
              <img
                className="w-auto h-12"
                src={
                  sport.logo
                    ? sport.logo
                    : 'https://ojasyog.com/wp-content/uploads/2022/02/421-4212617_person-placeholder-image-transparent-hd-png-download.png'
                }
                alt={sport.name}
                loading="lazy"
              />
              <span className="mt-2 text-zinc-800 text-xs font-normal leading-tight">
                {sport.name}
              </span>
            </>
          );

          return sport.sport.id === 'mma' ? (//|| sport.sport.id === 'golf' || sport.sport.id === 'esports' ? (
            <div
              key={index}
              className="flex flex-col items-center bg-white rounded-xl border border-zinc-400 text-center p-2 hover:bg-gray-200"
            >
              {cardContent}
            </div>
          ) : (
            <Link
              to={{
                pathname: `/sports/team/${sport.id}`,
                state: { sportData: sport },
              }}
              key={index}
              className="flex flex-col items-center bg-white rounded-xl border border-zinc-400 text-center p-2 hover:bg-gray-200"
            >
              {cardContent}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default TeamsCards;
